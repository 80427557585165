import React from 'react';

import CategoryHero from 'components/heros/categoryHero';

const NotFoundPage = () => (
  <CategoryHero
    title="404 Not Found"
    subtitle="The page you are looking for does not exist"
  />
);

export default NotFoundPage;
